import { Injectable } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { WHONET_AntibioticsDto} from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AntibioticsEditComponent } from '../whonet-antibiotics-edit-modal/whonet-antibiotics-edit-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { LocalizationService } from '@node_modules/abp-ng2-module';





@Injectable({
    providedIn: 'root'
})
export class AntibioticsUxService {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    constructor(private modalService: NzModalService, private localization: LocalizationService) { }

    public openCreateOrEditModal(option: { dataItem: WHONET_AntibioticsDto; isView?: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.isView ?  this.localization.localize('_XemChiTiet', this.localizationSourceName) : (option.dataItem?.id > 0 ? this.localization.localize('_CapNhat', this.localizationSourceName) : this.localization.localize('_ThemMoi', this.localizationSourceName));
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
          nzContent: AntibioticsEditComponent,
            nzComponentParams: {
                dataItem: Object.assign(new WHONET_AntibioticsDto(), { ...option.dataItem }),
                isView: option.isView,
            },
            nzWidth: '70%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: WHONET_AntibioticsDto) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }


}
