import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';
import { HAI_GuiMailDinhKyServiceProxy } from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.CommonSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.TrackingLogServiceProxy,

        //Cơ sở y tế
        ApiServiceProxies.ThongTinCoSoServiceProxy,

        //Tra cứu hành chính
        ApiServiceProxies.TraCuuNhanVienServiceProxy,
        ApiServiceProxies.TraCuuDanhSachCoSoServiceProxy,

        //Tra cứu mã kê khai
        ApiServiceProxies.TraCuuGiaDichVuServiceProxy,

        //Công khai y tế
        ApiServiceProxies.ThongTinHanhChinhServiceProxy,
        ApiServiceProxies.HoSoCongKhaiServiceProxy,

        //Danh mục
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,

        ApiServiceProxies.HinhThucToChucServiceProxy,
        ApiServiceProxies.DonViHanhChinhServiceProxy,
        ApiServiceProxies.KhoaPhongServiceProxy,
        ApiServiceProxies.NhanVienServiceProxy,
        ApiServiceProxies.GiaDichVuServiceProxy,
        ApiServiceProxies.TrangThietBiServiceProxy,
        ApiServiceProxies.GiaThuocServiceProxy,
        ApiServiceProxies.DanTocServiceProxy,
        ApiServiceProxies.ICD9ServiceProxy,
        ApiServiceProxies.ICD10ServiceProxy,
        ApiServiceProxies.DanhSachICD10ServiceProxy,
        ApiServiceProxies.TinhHuongYKhoaServiceProxy,
        ApiServiceProxies.DanhMucSuCoServiceProxy,
        ApiServiceProxies.NhomSuCoServiceProxy,
        ApiServiceProxies.NhomNguyenNhanServiceProxy,
        ApiServiceProxies.PhuongThucPhatHienSuCoServiceProxy,
        ApiServiceProxies.KetCucBenhNhanServiceProxy,
        ApiServiceProxies.KetCucBenhVienServiceProxy,
        ApiServiceProxies.PhanNhomChinhServiceProxy,

        //Danh Mục Chung
        ApiServiceProxies.DichVuKyThuatTuongDuongServiceProxy,
        ApiServiceProxies.PhanLoaiPTTTServiceProxy,
        ApiServiceProxies.ChuyenKhoaServiceProxy,
        ApiServiceProxies.DMBoNganhQuanLyServiceProxy,
        ApiServiceProxies.DMCoQuanChuQuanServiceProxy,
        ApiServiceProxies.DMChucVuServiceProxy,
        ApiServiceProxies.DMLyDoVaoVienServiceProxy,
        ApiServiceProxies.DanhMucVNCodeServiceProxy,
        ApiServiceProxies.DanhMucLoiServiceProxy,
        ApiServiceProxies.DieuKienICD10ServiceProxy,
        ApiServiceProxies.DMCoderServiceProxy,

        //Báo cáo tết
        ApiServiceProxies.BCT_DanhMucChiSoServiceProxy,
        ApiServiceProxies.BCT_DotBaoCaoServiceProxy,
        ApiServiceProxies.BCT_BaoCaoServiceProxy,
        ApiServiceProxies.BCT_BenhNhanServiceProxy,

        //Quản lý tủ vong
        ApiServiceProxies.QLTV_ChiSoServiceProxy,
        ApiServiceProxies.QLTV_LoaiBaoCaoServiceProxy,
        ApiServiceProxies.QLTV_BaoCaoServiceProxy,
        ApiServiceProxies.QLTV_ThongKeServiceProxy,
        ApiServiceProxies.QLTV_APIServiceProxy,
        ApiServiceProxies.QLTV_DuyetBaoCaoServiceProxy,
        ApiServiceProxies.QLTV_MaICD10ServiceProxy,

        //Quản lý phòng xét nghiệm
        ApiServiceProxies.MaDVKTXetNghiemServiceProxy,
        ApiServiceProxies.PhongXetNghiemServiceProxy,

        //Hệ thống
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.TaiKhoanServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,
        ApiServiceProxies.CauHinhVaiTroServiceProxy,
        ApiServiceProxies.CauHinhDangKyServiceProxy,
        ApiServiceProxies.DangKyTaiKhoanServiceProxy,
        ApiServiceProxies.DotTapHuanServiceProxy,
        ApiServiceProxies.DotTapHuanCoSoServiceProxy,
        ApiServiceProxies.DanhMucTaiLieuServiceProxy,
        ApiServiceProxies.TaiLieuThamKhaoServiceProxy,

        //Ký điện tử
        ApiServiceProxies.KySoServiceProxy,
        ApiServiceProxies.ChuKyServiceProxy,

        //Thanh toán DRG
        ApiServiceProxies.DRG_BenhNhanServiceProxy,

        //Sự cố y khoa
        ApiServiceProxies.BaoCaoSuCoYKhoaServiceProxy,
        ApiServiceProxies.ThongKeSuCoYKhoaServiceProxy,
        ApiServiceProxies.DotBaoCaoQuyServiceProxy,
        ApiServiceProxies.BaoCaoQuyServiceProxy,
        ApiServiceProxies.TongHopBaoCaoQuyServiceProxy,
        ApiServiceProxies.LichSuSuCoYKhoaServiceProxy,

        //Báo cáo tiến độ
        ApiServiceProxies.BCTDKyBaoCaoServiceProxy,
        ApiServiceProxies.BCTDBaoCaoServiceProxy,
        ApiServiceProxies.BCTDDanhMucChiSoServiceProxy,

        //Quản lý AMR
        ApiServiceProxies.AMR_OrganisationServiceProxy,
        ApiServiceProxies.AMR_UploadLogServiceProxy,
        ApiServiceProxies.AMR_EventServiceProxy,
        ApiServiceProxies.AMR_SpecimenServiceProxy,
        ApiServiceProxies.AMR_GuidelineServiceProxy,
        ApiServiceProxies.HoiDapServiceProxy,
        ApiServiceProxies.AMR_MonthlyDataSummaryServiceProxy,
        ApiServiceProxies.AMR_DataSummaryServiceProxy,
        ApiServiceProxies.AMR_BaoCaoBenhVienServiceProxy,
        ApiServiceProxies.AMR_ReportServiceProxy,
        ApiServiceProxies.AMR_DashboardServiceProxy,

        //Quản lý HAI
        ApiServiceProxies.HAI_OrganisationServiceProxy,
        ApiServiceProxies.HAI_CaseBsiServiceProxy,
        ApiServiceProxies.HAI_SSIClassServiceProxy,
        ApiServiceProxies.HAI_DepartmentServiceProxy,
        ApiServiceProxies.HAI_CaseUtiServiceProxy,
        ApiServiceProxies.HAI_SSIDataServiceProxy,
        ApiServiceProxies.HAI_MonthlyDataSummaryServiceProxy,
        ApiServiceProxies.HAI_DuLieuTongHopServiceProxy,
        ApiServiceProxies.HAI_TacNhanGayBenhServiceProxy,
        ApiServiceProxies.HAI_MoHinhKhangSinhServiceProxy,
        ApiServiceProxies.HAI_HoanThanhDuLieuServiceProxy,
        ApiServiceProxies.HAI_NhayCamKhangSinhServiceProxy,
        ApiServiceProxies.HAI_TongQuanServiceProxy,
        ApiServiceProxies.HAI_ViKhuanHoiSinhServiceProxy,
        ApiServiceProxies.HAI_KhangSinhPhoBienServiceProxy,
        ApiServiceProxies.HAI_GuiMailDinhKyServiceProxy,

        //Whonet
        ApiServiceProxies.WHONET_BreakpointsServiceProxy,
        ApiServiceProxies.WHONET_AntibioticsServiceProxy,
        ApiServiceProxies.WHONET_IntrinsicResistanceServiceProxy,
        ApiServiceProxies.WHONET_OrganismsServiceProxy,
        ApiServiceProxies.WHONET_ExpertInterpretationRulesServiceProxy,
        ApiServiceProxies.WHONET_ExecuteServiceProxy,
        ApiServiceProxies.WHONET_WhonetResourceServiceProxy,
        ApiServiceProxies.WHONET_SettingsServiceProxy,

        //Kho dữ liệu
        ApiServiceProxies.KDL_LichSuAPIServiceProxy,
        ApiServiceProxies.KDL_DayDHIS2ServiceProxy,
        ApiServiceProxies.KDL_APIVSSServiceProxy,

        //Dashboard
        ApiServiceProxies.DashboardServiceProxy,

        //Combo
        ApiServiceProxies.ComboServiceProxy,

        //Chất lượng PXN
        ApiServiceProxies.TieuChiCLPXNServiceProxy,
        ApiServiceProxies.DoanDanhGiaCLPXNServiceProxy,
        ApiServiceProxies.DanhGiaCLPXNServiceProxy,
        ApiServiceProxies.DanhMucCLPXNServiceProxy,

        //ApiServiceProxies.DashboardServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule { }
