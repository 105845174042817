<ora-filter-spin (onSearch)="refresh()" (onRefresh)="clear()" (keydown.enter)="refresh()" [widthColSeach]="16"
                 [hasAdvancedSearch]="true" [isShowTitleSearch]="true" [showAdvancedSearch]="true" [titleInputSearch]="this.l('_TimKiem')">
    <input nz-input class="txtSearch" type="text" [(ngModel)]="searchDto.filter"
           placeholder="{{'_NhapTuKhoaDeTimKiem' | localize}}" />

    <ng-container advancedSearch>
        <div nz-row [nzGutter]="8">
            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
                <label class="font-weight-normal">{{ '_LoaiHinhCoSo' | localize }}</label>
                <enum-combo [EnumCode]="ComboBoxEnumCode.LEVEL" [(ngModel)]="searchDto.arrLevelSearch"
                            [isMulti]="true">
                </enum-combo>
            </div>
            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" *ngIf="!maTinh">
                <label class="font-weight-normal">{{ '_Tinh' | localize }}</label>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="searchDto.maTinhSearch" [placeHolder]="'-'+this.l('_Chon')+'-'"
                                               [capDo]="CapDoDonViHanhChinh.Tinh"></don-vi-hanh-chinh-chinh-combo>
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" *ngIf="!maTinh && !maHuyen">
                <label class="font-weight-normal">{{ '_Huyen' | localize }}</label>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="searchDto.maHuyenSearch" [capDo]="CapDoDonViHanhChinh.Huyen"
                                               [parentCode]="searchDto.maTinhSearch" [placeHolder]="'-'+this.l('_Chon')+'-'"></don-vi-hanh-chinh-chinh-combo>
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
                <label class="font-weight-normal">{{ 'Pages_DanhMuc_YTe_CoQuanChuQuan' | localize }}</label>
                <table-combo [TableEnum]="ComboBoxTableCode.GetAllCoQuanChuQuan" [(ngModel)]="searchDto.pIdSearch">
                </table-combo>
            </div>
        </div>
    </ng-container>

</ora-filter-spin>
<ora-table #datatable [rows]="dataList" [isLoading]="isTableLoading" [isMulti]="isMulti" [totalRows]="totalItems"
           [(pageSize)]="pageSize" [(page)]="pageNumber" (pageSizeChange)="refresh()" (pageChange)="pageNumberChange()"
           (sortChange)="gridSort($event)" [(selectedDataItems)]="selectedDataItems"
           [scroll]="{ x: '600px', y:'calc(100vh - 56px)' }">
    <ng-container titleTable>
        <label class="font-weight-normal">
            {{ '_DanhSach' | localize }} ({{ totalItems | oraNumber: '1.0-0':'en' }} {{ '_BanGhi' | localize }})
        </label>
    </ng-container>

    <ora-column dataType="Select" width="50"></ora-column>
    <ora-column key="maCoSo" header="{{ '_MaHeThong' | localize }}" width="80"></ora-column>
    <ora-column key="maKCB" header="{{ '_MaKCBbhyt' | localize }}" width="80"></ora-column>
    <ora-column key="tenCoSo" header="{{ '_TenCoSo' | localize }}" width="350"></ora-column>
    <ora-column key="sLevel" header="{{ '_LoaiHinhCoSo' | localize }}" width="120"></ora-column>
    <ora-column key="tenTinh" header="{{ '_Tinh' | localize }}" width="150"></ora-column>
    <ora-column key="tenHuyen" header="{{ '_Huyen' | localize }}" width="150"></ora-column>
</ora-table>

<ng-container>
    <nz-form-item *nzModalFooter>
        <nz-form-control>
            <div>
                <btn-add-item typeBtn="secondary" (clickEvent)="close()" icon="close" title="{{ 'Close' | localize}}" text="{{ 'Close' | localize}}">
                </btn-add-item>
                <btn-add-item *ngIf="selectedDataItems.length>0" typeBtn="primary" (clickEvent)="save()" icon="save"
                              title="{{ '_Chon' | localize}}" text="{{ '_Chon' | localize}}">
                </btn-add-item>
            </div>
        </nz-form-control>
    </nz-form-item>
</ng-container>
