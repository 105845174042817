<div *ngIf="dataItem" [id]="formId">

    <div nz-row nzGutter="2">

        <div nz-col class="gutter-row" [nzLg]="12" [nzXs]="24">
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label>{{'_LoaiDangKy' | localize}}: </nz-form-label>
                    <span> ({{dataItem.sLoaiDangKy}})</span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <ora-field-set tieuDe="{{'_ChucNangHienTai' | localize}}">

        <div nz-row nzGutter="2">
            <div nz-col class="gutter-row" [nzLg]="16" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label nzRequired>{{'_CoSoYTe' | localize}}</nz-form-label>
                        <div>{{dataItem.tenCoSo}}</div>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col class="gutter-row" [nzLg]="8" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label nzRequired>{{'_LoaiHinhCoSo' | localize}}</nz-form-label>
                        <div>{{dataItem.sLevel}}</div>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col class="gutter-row" [nzLg]="16" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label nzRequired>{{'_HoTen' | localize}}</nz-form-label>
                        <div>{{dataItem.name}}</div>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col class="gutter-row" [nzLg]="8" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label>{{'_PhongBan' | localize}}</nz-form-label>
                        <div>{{dataItem.phongBan}}</div>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col class="gutter-row" [nzLg]="16" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label nzRequired>{{'_TaiKhoan' | localize}}</nz-form-label>
                        <div>{{dataItem.userName}}</div>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col class="gutter-row" [nzLg]="8" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label>{{'Pages_DanhMuc_YTe_ChucVu' | localize}}</nz-form-label>
                        <div>{{dataItem.chucVu}}</div>
                    </nz-form-control>
                </nz-form-item>
            </div>

        </div>
    </ora-field-set>

    <ora-field-set [tieuDe]="this.l('_DangKyBoSungMucDichSuDung')">

        <div nz-row nzGutter="2">
            <div nz-col class="gutter-row" [nzLg]="12" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label [nzSpan]="24" nzRequired>{{'_PhanHeBoSung' | localize}}</nz-form-label>
                        <nz-select nzAllowClear nzPlaceHolder="{{'_Chon' | localize}}" [(ngModel)]="dataItem.cauHinhDangKyId">
                            <ng-container *ngFor="let item of dataItem.arrCauHinh">
                                <nz-option *ngIf="!item.isActive"
                                           [nzLabel]="item.displayText"
                                           [nzValue]="item.value"></nz-option>
                            </ng-container>
                        </nz-select>
                        <validation-custom [sModel]="dataItem.cauHinhDangKyId"></validation-custom>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col class="gutter-row" [nzLg]="12" [nzXs]="24">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label>{{'_ChoDuyet' | localize}}</nz-form-label>
                        <div>
                            <ul>
                                <ng-container *ngFor="let item of dataItem.arrCauHinh">
                                    <li *ngIf="item.isActive" class="_blue">{{item.displayText}}</li>
                                </ng-container>
                            </ul>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </ora-field-set>

</div>

<div *nzModalFooter>
    <button type="button" nz-button nzType="primary" (click)="guiDangKy()"><i nz-icon nzType="send"></i>{{'_Gui' | localize}}</button>
    <button type="button" nz-button nzType="default" (click)="close()">{{'Close' | localize}}</button>
</div>
